import { fetchSummarySales } from '../api';
export default {
  data() {
    const now = new Date();
    return {
      details: null,
      loading: false
    };
  },
  created() {
    this.fetchDetails();
  },
  methods: {
    fetchDetails() {
      this.details = null;
      this.loading = true;
      fetchSummarySales().done(responseData => {
        if (responseData) {
          this.details = responseData;
          this.createTable();
        }
      }).fail(response => {
        const responseData = response.responseJSON;
      }).always(() => {
        this.loading = false;
      });
    },
    createTable() {
      const data = this.details.results;
      const columns = this.details.columns;
      if (this.$table) {
        this.$table.destroy();
      }
      this.$table = $(this.$refs.table).DataTable({
        data,
        info: false,
        order: [],
        paging: false,
        columns,
        orderging: false,
        searching: false
      });
    }
  }
};