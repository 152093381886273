import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'admin-lte/dist/js/adminlte.min';
import 'vue2-datepicker/index.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'admin-lte/plugins/toastr/toastr.min.css';
import 'admin-lte/plugins/jsgrid/jsgrid.min.css';
import 'admin-lte/plugins/jsgrid/jsgrid-theme.min.css';
import 'admin-lte/dist/css/adminlte.min.css';
import 'datatables.net-dt/css/jquery.dataTables.css';
import '../css/fouc.css';
import Vue from 'vue';
import store from './store';
import { Kpi } from './Kpis';
import { SalesDashboard } from './Sales';
import { formatDate, formatCurrency } from './Filters';
import { Vendor } from './Vendors';
import { YTDSalesChart, LedgersTable } from './Dashboard';
import { StatementsTable } from './Statements';
$(function () {
  Vue.filter('formatDate', formatDate);
  Vue.filter('formatCurrency', formatCurrency);
  const app = new Vue({
    el: '#app',
    components: {
      ///////////////////
      // KPI Component(s)
      ///////////////////
      Kpi,
      /////////////////////
      // Sales Component(s)
      /////////////////////
      SalesDashboard,
      //////////////////////
      // Vendor Component(s)
      //////////////////////
      Vendor,
      'ytd-sales-chart': YTDSalesChart,
      'statements-table': StatementsTable,
      'ledgers-table': LedgersTable
    },
    store
  });
});