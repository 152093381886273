import Chart from 'chart.js';
import { request } from './requests';
export default class CustomChart {
  constructor(config) {
    this.config = Object.assign({
      el: null,
      url: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: false
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            stacked: false
          }]
        },
        legend: {
          display: false
        }
      }
    }, config);
    this.chart = null;
  }
  init() {
    this.fetch().done(data => {
      if (this.chart) {
        this.updateChart(data);
      } else {
        this.createChart(data);
      }
    }).fail(jqXHR => {
      console.error('Failed to fetch data from the server.');
    });
  }
  fetch() {
    const {
      url
    } = this.config;
    return request({
      url: url,
      method: 'GET',
      spinner: false
    });
  }
  createChart(data) {
    const {
      el,
      options
    } = this.config;
    this.chart = new Chart(el, {
      type: data.type,
      options,
      data
    });
  }
  updateChart(data) {
    this.chart.data = data;
    this.chart.update();
  }
}