import { request } from "../requests";
import 'jsGrid';
export default {
  name: "ContactsTable",
  mounted() {
    jQuery(this.$refs.table).jsGrid({
      width: '100%',
      fields: [{
        type: 'text',
        name: 'statement_date',
        title: 'Statement Date'
      }, {
        type: 'text',
        name: 'total',
        title: 'Statement Total'
      }],
      height: 'auto',
      paging: true,
      editing: false,
      heading: true,
      sorting: true,
      autoload: true,
      pageSize: 5,
      filtering: false,
      selecting: true,
      editButton: false,
      noDataContent: 'No Statements Found',
      controller: {
        loadData: this.onLoadData.bind(this)
      },
      rowClick: this.onDataClicked.bind(this)
    });
  },
  methods: {
    onLoadData(filter = null) {
      let data = filter;
      const url = '/tables/statements';
      return request({
        url: url,
        method: 'GET',
        spinner: false,
        data
      });
    },
    onDataClicked(data) {
      window.location.href = '/statements/' + data.item.id;
    }
  }
};