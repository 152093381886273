import 'datatables.net';
import formatDate from '../Filters/formatDate';
import formatCurrency from '../Filters/formatCurrency';
export default {
  name: 'VendorPayments',
  props: {
    payments: {
      type: Array,
      required: true
    }
  },
  mounted() {
    this.createTable();
  },
  methods: {
    createTable() {
      const columns = [{
        data: 'date',
        title: 'Date'
        // render(cellData, type, row) {
        //     return formatDate(cellData);
        // },
      }, {
        data: 'amount',
        title: 'Amount',
        render(cellData, type, row) {
          return formatCurrency(cellData);
        }
      }, {
        data: 'notes',
        title: 'Notes'
      }];
      const data = this.payments;
      const self = this;
      if (this.$table) {
        this.$table.destroy();
      }
      this.$table = $(this.$refs.table).DataTable({
        data,
        info: false,
        order: [],
        paging: false,
        columns,
        orderging: false,
        searching: false
      });
      const $rows = $(this.$refs.table).find('tr');
      $rows.on('click', function () {
        const clickedRow = self.$table.row(this);
        self.$emit('select', clickedRow.data());
      });
    }
  }
};