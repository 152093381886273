var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "label",
              {
                staticClass: "col-form-label",
                attrs: { for: "please_invoice_id" },
              },
              [_vm._v("Filter By Project")]
            ),
            _vm._v(" "),
            _c("multiselect", {
              class: { "is-invalid": _vm.errors.please_invoice_id },
              attrs: {
                id: "please_invoice_id",
                "track-by": "id",
                label: "value",
                options: this.projects,
                searchable: false,
                "close-on-select": true,
                "allow-empty": true,
              },
              model: {
                value: _vm.filters.please_invoice_id,
                callback: function ($$v) {
                  _vm.$set(_vm.filters, "please_invoice_id", $$v)
                },
                expression: "filters.please_invoice_id",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-lg-6" },
        [
          _c(
            "label",
            { staticClass: "col-form-label", attrs: { for: "type" } },
            [_vm._v("Filter By Type")]
          ),
          _vm._v(" "),
          _c("multiselect", {
            class: { "is-invalid": _vm.errors.type },
            attrs: {
              id: "type",
              options: this.types,
              searchable: false,
              "close-on-select": true,
              "allow-empty": true,
            },
            model: {
              value: _vm.filters.type,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "type", $$v)
              },
              expression: "filters.type",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [_c("div", { ref: "table" })]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }