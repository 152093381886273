import CustomChart from "../charts";
export default {
  name: "YTDSalesChart",
  mounted() {
    const chart = new CustomChart({
      el: $('#chart'),
      url: '/charts/ytdsales'
    });
    chart.init();
  }
};