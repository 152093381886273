var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [_c("summary-sales-card")], 1),
    _vm._v(" "),
    _c("div", { staticClass: "col-12" }, [_c("total-sales-card")], 1),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12" },
      _vm._l(_vm.employees, function (employee) {
        return _c("employee-sales-card", {
          key: employee.id,
          attrs: { "employee-id": employee.id },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }