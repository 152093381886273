import { mapState, mapActions } from 'vuex';
import 'datatables.net';
import 'datatables.net-fixedcolumns';
export default {
  data() {
    return {
      employeeSelected: null
    };
  },
  created() {
    this.fetchEmployees();
  },
  mounted() {
    this.fetchUtilization().then(() => {
      this.createTable();
    });
  },
  methods: {
    ...mapActions('utilization', ['fetchEmployees', 'fetchUtilization']),
    createTable() {
      const {
        data,
        columns
      } = this.utilizationItems;
      columns[0].createdCell = (td, cellData, rowData, row, col) => {
        if (cellData.class) {
          $(td).addClass(cellData.class);
        }
      };
      columns[0].render = function (cellData, type, row) {
        return cellData.value;
      };
      if (this.$table) {
        this.$table.destroy();
      }
      this.$table = $(this.$refs.table).DataTable({
        data,
        info: false,
        order: [],
        paging: false,
        columns,
        scrollX: true,
        orderging: false,
        searching: false,
        fixedColumns: {
          leftColumns: 1,
          rightColumns: 0
        }
      });
    }
  },
  computed: {
    ...mapState('utilization', {
      employeeItems: state => state.employees.items,
      employeeErrors: state => state.employees.errors,
      employeeLoading: state => state.employees.loading,
      utilizationItems: state => state.utilization.items,
      utilizationErrors: state => state.utilization.errors,
      utilizationLoading: state => state.utilization.loading
    })
  },
  watch: {
    employeeSelected(newValue, oldValue) {
      this.$table.clear().draw();
      this.fetchUtilization(newValue).then(() => {
        const {
          data
        } = this.utilizationItems;
        this.$table.rows.add(data).draw();
      });
    }
  }
};