export default {
  namespaced: true,
  state: {
    csrf: fetchCSRF()
  },
  getters: {
    csrf(state) {
      return state.csrf;
    }
  },
  mutations: {
    csrf(state, value) {
      return state.csrf = value;
    }
  }
};
function fetchCSRF() {
  const csrf = getCSRFToken();
  if (!csrf) {
    return window.security && window.security.csrf ? window.security.csrf : null;
  }
  return csrf;
}

/**
 * Get the CSRF token from the cookie header.
 */
function getCSRFToken(name = 'csrf_cookie_name') {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}