import { mapState, mapActions, mapGetters } from 'vuex';
import VendorPayments from './VendorPayments';
export default {
  name: 'VendorDetails',
  props: {
    employeeId: {
      type: Number,
      required: true
    }
  },
  created() {
    this.fetchVendorDetails(this.employeeId);
  },
  computed: {
    ...mapState('vendors', {
      detailsItem: state => state.details.item,
      detailsErrors: state => state.details.errors,
      detailsLoading: state => state.details.loading
    }),
    ...mapGetters('vendors', ['getVendorBalance'])
  },
  methods: {
    ...mapActions('vendors', ['fetchVendorDetails']),
    onPaymentSelected(data) {}
  },
  components: {
    VendorPayments
  }
};