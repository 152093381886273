import _ from 'lodash';
import { fetchExpectedProjectValue } from '../api';
import EmployeeSalesChart from './EmployeeSalesChart';
export default {
  data() {
    const now = new Date();
    return {
      details: null,
      loading: false,
      filters: {
        year: now.getFullYear()
      }
    };
  },
  created() {
    this.fetchDetails();
  },
  watch: {
    filters: {
      handler: _.debounce(function (value) {
        this.fetchDetails();
      }, 800),
      deep: true
    }
  },
  methods: {
    fetchDetails() {
      this.details = null;
      this.loading = true;
      fetchExpectedProjectValue(this.filters).done(responseData => {
        if (responseData && responseData.result) {
          this.details = responseData.result;
        }
      }).fail(response => {
        const responseData = response.responseJSON;
      }).always(() => {
        this.loading = false;
      });
    }
  },
  computed: {
    years() {
      const now = new Date();
      const items = [];
      for (let i = 2019; i <= now.getFullYear(); i++) {
        items.push(i);
      }
      return items;
    }
  },
  components: {
    EmployeeSalesChart
  }
};