var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [
          _vm._v("\n                Ledgers\n            "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("div", { staticClass: "pb-3" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-8" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.name,
                        expression: "filters.name",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      placeholder: "Search by project name",
                    },
                    domProps: { value: _vm.filters.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.filters, "name", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    _c("date-picker", {
                      attrs: {
                        range: "",
                        format: "MM/DD/YYYY",
                        "value-type": "YYYY-MM-DD",
                      },
                      model: {
                        value: _vm.filters.date,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "date", $$v)
                        },
                        expression: "filters.date",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.ledgersLoading ? [_vm._m(0)] : _vm._e(),
            _vm._v(" "),
            _c("table", { ref: "table", staticClass: "table table-striped" }),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex justify-content-center py-5" }, [
      _c("i", { staticClass: "fas fa-2x fa-cog fa-spin" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }