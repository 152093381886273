import { fetchEmployees } from '../api';
import EmployeeSalesCard from './EmployeeSalesCard';
import SummarySalesCard from './SummarySalesCard';
import TotalSalesCard from './TotalSalesCard';
export default {
  data() {
    return {
      employees: []
    };
  },
  created() {
    fetchEmployees().done(responseData => {
      if (responseData && responseData.result) {
        this.employees = responseData.result;
      }
    });
  },
  components: {
    EmployeeSalesCard,
    SummarySalesCard,
    TotalSalesCard
  }
};