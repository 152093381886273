import Swal from 'sweetalert2';
import store from './store';
import toastr from 'toastr';
const spinner = Swal.mixin({
  showCancelButton: false,
  showConfirmButton: false,
  allowOutsideClick: false,
  allowEscapeKey: false,
  allowEnterKey: false,
  heightAuto: false
});
const defaults = {
  spinner: true,
  method: 'POST'
};

/**
 * A utility function for sending HTTP requests.
 */
function request(options) {
  const settings = {
    ...defaults,
    ...options
  };
  let data = {
    ...settings.data
  };

  // Only serialize to JSON for non-GET requests.
  if (settings.method !== 'GET') {
    data = JSON.stringify(settings.data);
  }
  if (settings.spinner) {
    spinner.fire({
      title: 'Please wait...',
      html: '<i class="fas fa-5x fa-cog fa-spin"></i>'
    });
  }
  return $.ajax({
    contentType: 'application/json',
    dataType: 'json',
    headers: {
      'X-CSRF-TOKEN': store.getters['security/csrf']
    },
    type: settings.method,
    data,
    url: settings.url
  }).done((data, textStatus, jqXHR) => {
    csrfRefresh(jqXHR);
  }).fail((jqXHR, textStatus, error) => {
    csrfRefresh(jqXHR);
    httpError(jqXHR);
  }).always(() => {
    spinner.close();
  });
}
function csrfRefresh(jqXHR) {
  // Store the newly generated CSRF token.
  store.commit('security/csrf', jqXHR.getResponseHeader('X-CSRF-REFRESH'));
}
function httpError(jqXHR) {
  switch (jqXHR.status) {
    // Form validation error.
    case 400:
      const responseData = jqXHR.responseJSON;
      if (responseData && responseData.message) {
        toastr.error(responseData.message, 'Error');
      }
      break;

    // Unauthorized request.
    case 403:
      window.location.replace('/login');
      break;

    // Probably a 500 error.
    default:
      toastr.error('Something went wrong on our servers. Please try again.', 'Error');
      break;
  }
}
export { request };