import { request } from "../requests";
import 'jquery';
import 'jsGrid';
import Multiselect from 'vue-multiselect';
export default {
  name: "LedgersTable",
  props: {
    projects: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    jQuery(this.$refs.table).jsGrid({
      width: '100%',
      fields: [{
        type: "text",
        name: "project_name",
        title: "Project Name"
      }, {
        type: "text",
        name: "status",
        title: "Status"
      }, {
        type: "text",
        name: "milestone_type",
        title: "Type"
      }, {
        type: "text",
        name: "commission_pct",
        title: "Percentage"
      }, {
        type: "text",
        name: "amount",
        title: "Amount"
      }, {
        type: "text",
        name: "payment_date",
        title: "Client Payment Date"
      }, {
        type: "text",
        name: "payout_date",
        title: "Commission Payout Date"
      }, {
        type: "text",
        name: "notes",
        title: "Notes"
      }],
      height: 'auto',
      paging: true,
      editing: false,
      heading: true,
      sorting: true,
      autoload: true,
      pageSize: 5,
      filtering: false,
      selecting: true,
      editButton: false,
      noDataContent: 'No Ledgers Found',
      controller: {
        loadData: this.onLoadData.bind(this)
      }
    });
  },
  data() {
    return {
      filters: {
        please_invoice_id: null,
        type: null
      },
      errors: {},
      types: ['Unknown', 'Design / Dev Milestone', 'Change Order Milestone', 'SEO Billable Milestone', 'SEO Recurring Milestone', 'SEO Recurring Milestone (Invoice)', 'PPC Billable Milestone (Setup Fee)', 'PPC Billable Milestone (No Setup Fee)', 'PPC Recurring Milestone', 'PPC Recurring Milestone (Invoice)', 'Retainer Support Billable Milestone', 'Retainer Support Billable Milestone (Time Trackers)', 'Retainer Support Recurring Milestone', 'Retainer Support Recurring Milestone (Invoice)', 'Social Media Billable Milestone (Setup Fee)', 'Social Media Billable Milestone (No Setup Fee)', 'Social Media Recurring Milestone', 'Social Media Recurring Milestone (Invoice)', 'Extended Care Billable Milestone', 'Extended Care Billable Milestone (Time Trackers)', 'Extended Care Recurring Milestone', 'Extended Care Recurring Milestone (Invoice)']
    };
  },
  watch: {
    'filters.please_invoice_id': function () {
      this.onFilterChanged();
    },
    'filters.type': function () {
      this.onFilterChanged();
    }
  },
  methods: {
    onLoadData(filter = null) {
      let data = filter;
      const url = '/tables/ledgers';
      return request({
        url: url,
        method: 'GET',
        spinner: false,
        data
      });
    },
    onFilterChanged() {
      jQuery(this.$refs.table).jsGrid('loadData', this.filters);
      jQuery(this.$refs.table).jsGrid('openPage', 1);
    }
  },
  components: {
    Multiselect
  }
};