import 'datatables.net';
import _ from 'lodash';
import { mapState, mapActions, mapMutations } from 'vuex';
import DatePicker from 'vue2-datepicker';
import formatDate from '../Filters/formatDate';
import formatCurrency from '../Filters/formatCurrency';
export default {
  name: 'VendorLedgers',
  props: {
    employeeId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      filters: {
        name: null,
        date: null
      }
    };
  },
  mounted() {
    this.fetchVendorLedgers(this.employeeId).then(() => {
      this.createTable();
    });
  },
  watch: {
    filters: {
      handler: _.debounce(function (values) {
        this.ledgersFilters(values);
        this.fetchVendorLedgers(this.employeeId).then(() => {
          this.updateTable();
        });
      }, 500),
      deep: true
    }
  },
  computed: {
    ...mapState('vendors', {
      ledgersItems: state => state.ledgers.items,
      ledgersErrors: state => state.ledgers.errors,
      ledgersLoading: state => state.ledgers.loading
    })
  },
  methods: {
    ...mapActions('vendors', ['fetchVendorLedgers']),
    ...mapMutations('vendors', ['ledgersFilters']),
    createTable() {
      const columns = [{
        data: 'name',
        title: 'Project Name',
        sortable: false
      }, {
        data: 'milestone',
        title: 'Milestone Type',
        sortable: false
      }, {
        data: 'change_order',
        title: 'Related Change Order',
        sortable: false
      }, {
        data: 'status',
        title: 'Payment Status',
        sortable: false
      }, {
        data: 'date',
        title: 'Date',
        sortable: true
        // render(cellData, type, row) {
        //     return formatDate(cellData);
        // },
      }, {
        data: 'amount',
        title: 'Amount',
        sortable: false,
        render(cellData, type, row) {
          return formatCurrency(cellData);
        }
      }, {
        data: 'notes',
        title: 'Notes',
        sortable: false
      }, {
        data: null,
        title: '',
        width: '10%',
        sortable: false,
        render(cellData, type, row) {
          return `
                            <a href="/projects/${cellData.pid}" class="btn btn-xs btn-block btn-default">
                                View Details
                            </a>
                        `;
        }
      }];
      const data = this.ledgersItems;
      const self = this;
      if (this.$table) {
        this.$table.clear();
        this.$table.destroy();
      }
      this.$table = $(this.$refs.table).DataTable({
        data,
        info: false,
        order: [],
        paging: true,
        columns,
        orderging: false,
        searching: false
      });

      // const $rows = $(this.$refs.table).find('tr');

      // $rows.on('click', function() {
      //     const clickedRow = self.$table.row(this);

      //     if (clickedRow) {
      //         const ledger = clickedRow.data();

      //         window.location.href = `/projects/${ledger.pid}`;
      //     }
      // });
    },
    updateTable() {
      if (!(this.$table && this.ledgersItems)) {
        return;
      }
      this.$table.clear();
      this.$table.rows.add(this.ledgersItems).draw();
    }
  },
  components: {
    DatePicker
  }
};