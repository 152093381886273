import { request } from '../requests';
export default {
  namespaced: true,
  state: {
    details: {
      item: null,
      errors: {},
      loading: false
    },
    ledgers: {
      items: [],
      errors: {},
      filters: {},
      loading: false
    },
    projects: {
      items: [],
      errors: {},
      filters: {},
      loading: false
    }
  },
  actions: {
    fetchVendorDetails({
      state,
      commit
    }, employeeID) {
      commit('detailsLoading', true);
      return request({
        spinner: false,
        method: 'GET',
        url: `/data/vendors/${employeeID}`
      }).then(({
        data
      }) => {
        commit('detailsItem', data);
      }).always(() => {
        commit('detailsLoading', false);
      });
    },
    fetchVendorLedgers({
      state,
      commit
    }, employeeID) {
      commit('ledgersLoading', true);
      const data = {
        ...state.ledgers.filters
      };
      return request({
        spinner: false,
        method: 'GET',
        data,
        url: `/data/vendors/${employeeID}/ledgers`
      }).then(({
        data
      }) => {
        commit('ledgersItems', data);
      }).always(() => {
        commit('ledgersLoading', false);
      });
    },
    fetchVendorProjects({
      state,
      commit
    }, employeeID) {
      commit('projectsLoading', true);
      const data = {
        ...state.projects.filters
      };
      return request({
        spinner: false,
        method: 'GET',
        data,
        url: `/data/vendors/${employeeID}/projects`
      }).then(({
        data
      }) => {
        commit('projectsItems', data);
      }).always(() => {
        commit('projectsLoading', false);
      });
    }
  },
  getters: {
    getVendorBalance(state) {
      const payment = state.details.item.payment_amount;
      const billing = state.details.item.billing_amount;
      return billing - payment;
    }
  },
  mutations: {
    detailsItem(state, item) {
      state.details.item = item;
    },
    detailsLoading(state, loading) {
      state.details.loading = loading;
    },
    ledgersItems(state, items) {
      state.ledgers.items = items;
    },
    ledgersLoading(state, loading) {
      state.ledgers.loading = loading;
    },
    ledgersFilters(state, filters) {
      state.ledgers.filters = filters;
    },
    projectsItems(state, items) {
      state.projects.items = items;
    },
    projectsLoading(state, loading) {
      state.projects.loading = loading;
    },
    projectsFilters(state, filters) {
      state.projects.filters = filters;
    }
  }
};