var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card mt-3 mb-0" }, [
    _c("div", { staticClass: "card-header" }, [
      _vm._v("\n        Vendor Payments\n    "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "table-responsive" }, [
      _c("table", { ref: "table", staticClass: "table table-striped" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }