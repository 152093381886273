var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [
          _vm._v("\n                Projects\n            "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("div", { staticClass: "pb-3" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-9" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.name,
                        expression: "filters.name",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      placeholder: "Search by project name",
                    },
                    domProps: { value: _vm.filters.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.filters, "name", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.display,
                          expression: "filters.display",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.filters,
                            "display",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", [
                        _vm._v(
                          "\n                                    Active\n                                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("option", [
                        _vm._v(
                          "\n                                    All\n                                "
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm.projectsLoading ? [_vm._m(0)] : _vm._e(),
            _vm._v(" "),
            _c("table", { ref: "table", staticClass: "table table-striped" }),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex justify-content-center py-5" }, [
      _c("i", { staticClass: "fas fa-2x fa-cog fa-spin" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }