import { render, staticRenderFns } from "./ExpectedProjectValue.vue?vue&type=template&id=2742c1fd"
import script from "./ExpectedProjectValue.vue?vue&type=script&lang=js"
export * from "./ExpectedProjectValue.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2742c1fd')) {
      api.createRecord('2742c1fd', component.options)
    } else {
      api.reload('2742c1fd', component.options)
    }
    module.hot.accept("./ExpectedProjectValue.vue?vue&type=template&id=2742c1fd", function () {
      api.rerender('2742c1fd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Sales/components/ExpectedProjectValue.vue"
export default component.exports