import { request } from '../requests';
export function fetchEmployees() {
  return request({
    method: 'GET',
    url: '/charts/sales'
  });
}
export function fetchEmployeeSales(employeeID, filters) {
  const url = `/charts/sales/${employeeID}`;
  const data = {
    ...filters
  };
  return request({
    spinner: false,
    method: 'GET',
    data,
    url
  });
}
export function fetchTotalSales(filters) {
  const url = '/charts/sales/total';
  const data = {
    ...filters
  };
  return request({
    spinner: false,
    method: 'GET',
    data,
    url
  });
}
export function fetchExpectedProjectValue(filters) {
  const url = '/charts/sales/expected-project-value';
  const data = {
    ...filters
  };
  return request({
    spinner: false,
    method: 'GET',
    data,
    url
  });
}
export function fetchSummarySales() {
  const url = '/data/summary';
  const data = {};
  return request({
    spinner: false,
    method: 'GET',
    data,
    url
  });
}