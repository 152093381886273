import 'datatables.net';
import _ from 'lodash';
import { mapState, mapActions, mapMutations } from 'vuex';
import formatDate from '../Filters/formatDate';
import formatCurrency from '../Filters/formatCurrency';
export default {
  name: 'VendorProjects',
  props: {
    employeeId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      filters: {
        name: null,
        display: 'Active'
      }
    };
  },
  mounted() {
    this.projectsFilters(this.filters);
    this.fetchVendorProjects(this.employeeId).then(() => {
      this.createTable();
    });
  },
  watch: {
    filters: {
      handler: _.debounce(function (values) {
        this.projectsFilters(values);
        this.fetchVendorProjects(this.employeeId).then(() => {
          this.updateTable();
        });
      }, 500),
      deep: true
    }
  },
  computed: {
    ...mapState('vendors', {
      projectsItems: state => state.projects.items,
      projectsErrors: state => state.projects.errors,
      projectsLoading: state => state.projects.loading
    })
  },
  methods: {
    ...mapActions('vendors', ['fetchVendorProjects']),
    ...mapMutations('vendors', ['projectsFilters']),
    createTable() {
      const columns = [{
        data: 'name',
        title: 'Project Name',
        sortable: false
      }, {
        data: 'fixed_cost',
        title: 'Fixed Project Cost (Dev)',
        sortable: false,
        render(cellData, type, row) {
          return formatCurrency(cellData);
        }
      }, {
        data: 'billed_total',
        title: 'Total Billed Amount',
        sortable: false,
        render(cellData, type, row) {
          return formatCurrency(cellData);
        }
      }, {
        data: 'paid_total',
        title: 'Paid Amount',
        sortable: false,
        render(cellData, type, row) {
          return formatCurrency(cellData);
        }
      }, {
        data: null,
        title: '',
        width: '10%',
        sortable: false,
        render(cellData, type, row) {
          return `
                            <a href="/projects/${cellData.id}" class="btn btn-xs btn-block btn-default">
                                View Details
                            </a>
                        `;
        }
      }];
      const data = this.projectsItems;
      const self = this;
      if (this.$table) {
        this.$table.clear();
        this.$table.destroy();
      }
      this.$table = $(this.$refs.table).DataTable({
        data,
        info: false,
        order: [],
        paging: true,
        columns,
        orderging: false,
        searching: false
      });

      // const $rows = $(this.$refs.table).find('tr');

      // $rows.on('click', function() {
      //     const clickedRow = self.$table.row(this);

      //     if (clickedRow) {
      //         const project = clickedRow.data();

      //         window.location.href = `/projects/${project.id}`;
      //     }
      // });
    },
    updateTable() {
      if (!(this.$table && this.projectsItems)) {
        return;
      }
      this.$table.clear();
      this.$table.rows.add(this.projectsItems).draw();
    }
  }
};