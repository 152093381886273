import { request } from '../requests';
export default {
  namespaced: true,
  state: {
    employees: {
      items: [],
      errors: {},
      loading: false
    },
    utilization: {
      items: [],
      errors: {},
      loading: false
    }
  },
  actions: {
    fetchEmployees({
      state,
      commit
    }) {
      commit('employeesLoading', true);
      return request({
        spinner: false,
        method: 'GET',
        url: '/data/employees'
      }).then(({
        data
      }) => {
        commit('employeesItems', data);
      }).always(() => {
        commit('employeesLoading', false);
      });
    },
    fetchUtilization({
      state,
      commit
    }, employee = null) {
      commit('utilizationLoading', true);
      const data = {
        employee
      };
      return request({
        method: 'GET',
        data,
        url: '/data/utilization'
      }).then(({
        data
      }) => {
        commit('utilizationItems', data);
      }).always(() => {
        commit('utilizationLoading', false);
      });
    }
  },
  mutations: {
    employeesItems(state, value) {
      state.employees.items = value;
    },
    employeesLoading(state, value) {
      state.employees.loading = value;
    },
    utilizationItems(state, value) {
      state.utilization.items = value;
    },
    utilizationLoading(state, value) {
      state.utilization.loading = value;
    }
  }
};