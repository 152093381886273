var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row my-4" }, [
      _c("div", { staticClass: "col-md-4 offset-md-8" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.employeeSelected,
                expression: "employeeSelected",
              },
            ],
            staticClass: "form-control",
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.employeeSelected = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
            },
          },
          [
            _c("option", { domProps: { value: null } }, [
              _vm._v(
                "\n                    Select employee...\n                "
              ),
            ]),
            _vm._v(" "),
            _vm._l(_vm.employeeItems, function (employee) {
              return _c("option", { domProps: { value: employee.id } }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(employee.name) +
                    "\n                "
                ),
              ])
            }),
          ],
          2
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "table-responsive" }, [
      _c("table", {
        ref: "table",
        staticClass: "table table-kpis table-striped",
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }