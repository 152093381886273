import VendorDetails from './VendorDetails';
import VendorLedgers from './VendorLedgers';
import VendorProjects from './VendorProjects';
export default {
  name: 'Vendor',
  props: {
    employeeId: {
      type: Number,
      required: true
    }
  },
  components: {
    VendorDetails,
    VendorLedgers,
    VendorProjects
  }
};