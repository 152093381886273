import Vue from 'vue';
import Vuex from 'vuex';

// Vue plugins.
Vue.use(Vuex);
import { vendors } from './Vendors';
import { security } from './Security';
import { utilization } from './Utilization';
const store = new Vuex.Store({
  modules: {
    vendors,
    security,
    utilization
  }
});
export default store;