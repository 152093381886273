import { Chart } from 'chart.js';
export default {
  props: {
    details: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.chart = new Chart(this.$el, {
      type: 'bar',
      data: {
        labels: this.details && this.details.series ? this.details.series.labels : [],
        datasets: this.details && this.details.series ? this.details.series.datasets : []
      },
      options: {
        responsive: true,
        maintainAspectRatio: false
      }
    });
  }
};