var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _vm.detailsLoading
      ? _c("div", { staticClass: "col-lg-12 py-5" }, [_vm._m(0)])
      : _vm._e(),
    _vm._v(" "),
    _vm.detailsItem
      ? _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-4 font-weight-bold" }, [
                    _vm._v(
                      "\n                        Vendor Name\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-8" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.detailsItem.name) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-4 font-weight-bold" }, [
                    _vm._v(
                      "\n                        Project Billing Amount\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-8" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm._f("formatCurrency")(
                            _vm.detailsItem.billing_amount
                          )
                        ) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-4 font-weight-bold" }, [
                    _vm._v(
                      "\n                        Vendor Payment Amount\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-8" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm._f("formatCurrency")(
                            _vm.detailsItem.payment_amount
                          )
                        ) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-4 font-weight-bold" }, [
                    _vm._v(
                      "\n                        Vendor Balance\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-8" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm._f("formatCurrency")(_vm.getVendorBalance)) +
                        "\n                    "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.detailsItem.payments
                  ? [
                      _c("vendor-payments", {
                        attrs: { payments: _vm.detailsItem.payments },
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex justify-content-center" }, [
      _c("i", { staticClass: "fas fa-2x fa-cog fa-spin" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }