var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vendor-details", { attrs: { "employee-id": _vm.employeeId } }),
      _vm._v(" "),
      _c("vendor-projects", { attrs: { "employee-id": _vm.employeeId } }),
      _vm._v(" "),
      _c("vendor-ledgers", { attrs: { "employee-id": _vm.employeeId } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }